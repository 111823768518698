import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import BottomButtons from "../BottomButtons";
import FormControlLabel1_5 from "../../utils/FormControlLabel1_5";

export const SpiritualBody = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const {
    creation,
    religiousIdentity,
    divineConsequences,
    termsLikeSpirit,
    innerCalling,
    energy,
    intuitions,
    connectionSoul,
  } = formData;
  const matches = useMediaQuery("(min-width:700px)");

  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;
  return (
    <Container maxWidth="xs">
      <h3>הגוף הנשמתי/רוחני</h3>
      <p>
        יש לסמן את המספר המתאים ביותר בסולם של 1-5 <br></br> (כאשר 1 מייצג "בכלל
        לא" ו-5 מייצג "בהחלט כן")
      </p>
      <RadioGroup
        row={matches ? "row" : ""}
        name="creation"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה "אלוקות" או "בריאה" נוכחת ביומיום שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="religiousIdentity"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה מהאמונה שלך כלפי עצמך, כלפי אחרים וכלפי הסביבה, מוגדרת ע"פ
          הזהות הדתית שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="divineConsequences"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מאמין שיש השלכות אלוקיות על המעשים שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="termsLikeSpirit"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מתחבר/ת למונחים כמו - רוח, נשמה, גורל, חלום?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="innerCalling"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שאת/ה מחובר/ת לייעוד הפנימי שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="energy"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מרגיש/ה אנרגיה בחדר או באדם שאת/ה פוגש/ת?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="intuitions"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה יש לך תחושות בטן ואינטואיציות כלפי אנשים וסיטואציות שונות?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="connectionSoul"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה חיבור לנשמה שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <BottomButtons
        blueBtnStyle={blueBtnStyle}
        pinkBtnStyle={pinkBtnStyle}
        navigation={navigation}
      />
    </Container>
  );
};
