import React from "react";
import { useForm, useStep } from "react-hooks-helper";

import { WholeBody } from "./stepForm/WholeBody";
import { PersonalDetails } from "./stepForm/PersonalDetails";
import { PhysicalBody } from "./stepForm/PhysicalBody";
import { EmotionalBody } from "./stepForm/EmotionalBody";
import { MentalBody } from "./stepForm/MentalBody";
import { SpiritualBody } from "./stepForm/SpiritualBody";
import { Review } from "./stepForm/Review";
import { Submit } from "./stepForm/Submit";
import { buttonsStyle } from "../utils/buttonsStyle";
import { radioGroupStyle } from "../utils/radioGroupStyle";
import {AppContext} from '../App'
import { useContext } from "react";

const blueBtnStyle = buttonsStyle.blue;
const pinkBtnStyle = buttonsStyle.pink;
const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
  radioGroupStyle;

const defaultData = {
  timestamp: new Date().toLocaleString(),
  //1
  name: "",
  gender: "",
  age: "",
  phone: "",
  email: "",

  //2
  selfBelief: "",
  goodSleep: "",
  water: "",
  fitness: "",
  nutrition: "",
  pain: "",
  connection: "",
  feelings: "",
  strongHealth: "",

  //3
  lastWeeks: "",
  dailyFunctioning: "",
  hardToDescribe: "",
  overwhelmed: "",
  creativeWays: "",
  emotionalBreakdown: "",
  emotionalIntensity: "",
  medication: "",

  //4
  organized: "",
  financial: "",
  wellPlanned: "",
  decisions: "",
  negativeThoughts: "",
  overThinking: "",
  thoughtsDrivingYou: "",
  expressYourThoughts: "",
  controlYourEmotions: "",

  //5
  creation: "",
  religiousIdentity: "",
  divineConsequences: "",
  termsLikeSpirit: "",
  innerCalling: "",
  energy: "",
  intuitions: "",
  connectionSoul: "",

  //6
  expressThePowers: "",
  uniqueness: "",
  sexuality: "",
  passion: "",
  depthCreation: "",
  harmony: "",
  doingWhatYouLove: "",
  socialGatherings: "",
};

const formDataEnglishToHebrew = {
  //1
  Name: "שם",
  Gender: "מגדר",
  Age: "גיל",
  Phone: "טלפון",
  Email: "אימייל",

  //2
  SelfBelief: "ריפוי עצמי",
  GoodSleep: "שינה טובה",
  Water: "שתיית מים",
  Fitness: "אימון כושר קבוע",
  Nutrition: "תזונה בריאה",
  Pain: "חווה כאב",
  Connection: "חיבור לגוף",
  Feelings: "סימנים ותחושות",
  StrongHealth: "בריאות חזקה",

  //3
  LastWeeks: "רגשות לאחרונה",
  DailyFunctioning: "השפעת המצב הרגשי",
  HardToDescribe: "תיאור הרגשות",
  Overwhelmed: "הצפה רגשית",
  CreativeWays: "ביטוי רגשי יצירתי",
  EmotionalBreakdown: "חוסר תפקוד",
  EmotionalIntensity: "עוצמת הרגש",
  Medication: "תרופות",

  //4
  Organized: "סדר וארגון",
  Financial: "התנהלות פיננסית",
  WellPlanned: "תוכניות ברורות",
  Decisions: "קושי בקבלת החלטות",
  NegativeThoughts: "מחשבות שליליות",
  OverThinking: "מחשבות טורדניות",
  ThoughtsDrivingYou: "ניהול מחשבתי",
  ExpressYourThoughts: "ביטוי המחשבות",
  ControlYourEmotions: "שליטה ברגשות",

  //5
  Creation: "נוכחות רוחנית",
  ReligiousIdentity: "זהות דתית",
  DivineConsequences: "השלכות אלוקיות",
  TermsLikeSpirit: "מונחים רוחניים",
  InnerCalling: "חיבור לייעוד",
  Energy: "חיבור אנרגטי",
  Intuitions: "תחושות בטן",
  ConnectionSoul: "חיבור לנשמה",

  //6
  ExpressThePowers: "ביטוי הכוחות שבי",
  Uniqueness: "ייחודיות",
  Sexuality: "מיניות",
  Passion: "תשוקה",
  DepthCreation: "ביטוי היצירה",
  Harmony: "הרמוניה בגופים",
  DoingWhatYouLove: "עושה מה שאוהב/ת",
  SocialGatherings: "מפגשים חברתיים",
};


export const MultiStepForm = ({steps}) => {


  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  const {
    setCurrentStep,
    currentStep
  } = useContext(AppContext);

  const submitForm = (e) => {
    e.preventDefault();
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwbPxqply60BW-bZGzQt9VixukkOn62zpk0KBZQuzvSBI7mRtRI0G2sQQIk9JAZbgliDw/exec";
    fetch(scriptURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).catch((error) => console.error("Error!", error.message));
  };
  const props = {
    formData,
    formDataEnglishToHebrew,
    setForm,
    navigation,
    blueBtnStyle,
    pinkBtnStyle,
    radioGroupStyle,
    submitForm,
    stepsLength:steps.length-1,
    setCurrentStep
  };

  switch (step.id) {
    case "personalDetails":
      return <PersonalDetails step={1}{...props} />;
    case "physicalBody":
      return <PhysicalBody step={2} {...props} />;
    case "emotionalBody":
      return <EmotionalBody step={3} {...props} />;
    case "mentalBody":
      return <MentalBody step={4} {...props} />;
    case "spiritualBody":
      return <SpiritualBody  step={5}{...props} />;
    case "wholeBody":
      return <WholeBody  step={6}{...props} />;
    case "review":
      return <Review step={7} {...props} />;
    case "submit":
      return <Submit {...props} />;
  }

  return (
    <div style={{ padding: "40px" }}>
      <h1>Multi step form</h1>
    </div>
  );
};
