import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import BottomButtons from "../BottomButtons";
import FormControlLabel1_5 from "../../utils/FormControlLabel1_5";

export const EmotionalBody = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const {
    lastWeeks,
    dailyFunctioning,
    hardToDescribe,
    overwhelmed,
    creativeWays,
    emotionalBreakdown,
    emotionalIntensity,
    medication,
  } = formData;
  const matches = useMediaQuery("(min-width:700px)");

  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;
  return (
    <Container maxWidth="xs">
      <h3>הגוף הרגשי</h3>
      <p>
        יש לסמן את המספר המתאים ביותר בסולם של 1-5 <br></br> (כאשר 1 מייצג "בכלל
        לא" ו-5 מייצג "בהחלט כן")
      </p>
      <RadioGroup
        row={matches ? "row" : ""}
        name="lastWeeks"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          בארבעת השבועות האחרונות האם הרגשת את הרגשות הבאים - שמחה, עצב,
          התרגשות, כעס, קנאה, חוסר אונים, פחד?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="dailyFunctioning"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה המצב הרגשי שלך פוגע לך בתפקוד היומיומי?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="hardToDescribe"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה זה קשה לך לתאר לאחרים מה את/ה מרגיש בדיבור?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="overwhelmed"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מרגיש/ה מוצפ/ת רגשית כשדברים מסויימים קורים?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="creativeWays"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מוצא/ת דרכים יצירתיות להביע את הרגשות שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="emotionalBreakdown"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם היה לך מקרה כלשהו בעבר שבשל הצפה רגשית קשה לא יכולת לתפקד ברמה
          היומיומית ?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="emotionalIntensity"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          באיזו עוצמה את/ה מרגיש/ה רגשות כשהם עולים?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="medication"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה נוטל/ת תרופות עבור חרדה או דיכאון?{" "}
        </FormLabel>

        <FormControlLabel
          value="כן"
          control={<Radio style={radioStyle} size="small" />}
          label="כן"
        />
        <FormControlLabel
          value="לא"
          control={<Radio style={radioStyle} size="small" />}
          label="לא"
        />
        <FormControlLabel
          value="מעדיפ/ה לא לשתף"
          control={<Radio style={radioStyle} size="small" />}
          label="מעדיפ/ה לא לשתף"
        />
      </RadioGroup>

      <BottomButtons
        blueBtnStyle={blueBtnStyle}
        pinkBtnStyle={pinkBtnStyle}
        navigation={navigation}
      />
    </Container>
  );
};
