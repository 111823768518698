import React from "react";
import Button from "@material-ui/core/Button";
import { useState, useContext } from "react";
import { buttonsStyle } from "../utils/buttonsStyle";
import { AppContext } from "../App";

import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
const BottomButtons = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
}) => {
  const { setCurrentStep, currentStep } = useContext(AppContext);
  const forward = () => {
    navigation.next();
    window.scrollTo(1, 0);

    setCurrentStep((prev) => prev * 1 + 1);
  };
  const back = () => {
    navigation.previous();
    window.scrollTo(0, 0);
    setCurrentStep((prev) => prev - 1);
  };
  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
      <Button variant="contained" style={pinkBtnStyle} onClick={back}>
        חזור
      </Button>
      <Button variant="contained" style={blueBtnStyle} onClick={forward}>
        המשך
      </Button>
    </div>
  );
};

export default BottomButtons;
