export const radioGroupStyle = {
  wraperStyle: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "30px",
    marginTop: "18px",
  },
  mainLabelStyle: {
    fontSize: "24px",
    color: "#202124",
    width: "100%",
    marginBottom: "10px",
  },
  subMainLabelStyle: {
    fontSize: "17px",
    color: "#202124",
    lineHeight: "15px",
    padding: "10px",
  },
  radioStyle: { color: "#21B6AE" },
};
