import React from "react";
import { useState, useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { MultiStepForm } from "./components/MultiStepForm";
import ProgressSteps from "./components/ProgressSteps";
import WellCome from "./components/WellCome";
import HeContainer from "./utils/hebrewContainer";
export const AppContext = React.createContext();

function App() {
  const steps = [
    { id: "personalDetails", index: 1 },
    { id: "physicalBody", index: 2 },
    { id: "emotionalBody", index: 3 },
    { id: "mentalBody", index: 4 },
    { id: "spiritualBody", index: 5 },
    { id: "wholeBody", index: 6 },
    { id: "review", index: 7 },
    { id: "submit", index: 8 },
  ];
  const [isStart, setisStart] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <div>
      <AppContext.Provider
        value={{
          currentStep,
          setCurrentStep,
        }}
      >
        <Header />
        {isStart ? (
          <HeContainer>
            <ProgressSteps steps={steps} />
            <MultiStepForm steps={steps} sx={{ paddin: "30px" }} />
            <br></br>
            <br></br>
          </HeContainer>
        ) : (
          <WellCome setisStart={setisStart} />
        )}
        <Footer />
      </AppContext.Provider>
    </div>
  );
}

export default App;
