import { Box, Button } from "@material-ui/core";
import React from "react";
import { buttonsStyle } from "../utils/buttonsStyle";
const blueBtnStyle = buttonsStyle.blue;
const pinkBtnStyle = buttonsStyle.pink;
const WellCome = ({ setisStart }) => {
  const forward = () => {
    setisStart(true);
    window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        style={{
          width: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 30px 100px 30px",
          border: "1px solid #ccc",
          padding: "40px",
          borderRadius: "8px",
        }}
      >
        <h3>שאלון חמשת הגופים - לעלות בתדר ההגשמתי</h3>
        <p>
          <p>
            שאלון זה נועד לתת לך סטטוס התפתחות במרחבי החיים השונים בהתבסס על
            מודל 5 הגופים. 5 הגופים המרכיבים כל אדם הינם:
          </p>
          <ol>
            <li>
              <b>הגוף הפיזי</b> - כל דבר הנוגע בפעילות פיזית-גופנית (אכילה,
              שתייה, שינה, תנועה, טיול, חושים)
            </li>
            <li>
              <b>הגוף הרגשי </b>- כל מה שקשור בתנועה רגשית, הסכמה להרגיש כל רגש
            </li>
            <li>
              <b>הגוף השכלי </b>- כל מה שקשור בתנועת המחשבות, התכנון, הרציונאל,
              קוגינציה
            </li>
            <li>
              <b>הגוף הנשמתי/רוחני</b> - כל מה שקשור באנרגיות, אינטואיציה,
              ייעוד, חיבור עמוק, תכלית וחלום
            </li>
            <li>
              <b>הגוף השלם - יצרי/מיני</b> - כל מה שקשור ביצירה, תשוקה, מעין
              השלמה של כל שאר הגופים
            </li>
          </ol>
        </p>

        <Button
          onClick={forward}
          variant="contained"
          style={{ ...blueBtnStyle, margin: "auto" }}
        >
          כניסה לשאלון
        </Button>
      </Box>
    </div>
  );
};

export default WellCome;
