import React from "react";
import { useState, useContext } from "react";
import { AppContext } from "../App";

const stepsContainerStyle = {
  maxWidth: "450px",
  display: "flex",
  justifyContent: "center",
  margin: "auto",
};
const stepCyrclStyle = {
  width: window.innerWidth > 580 ? "50px" : "30px",
  height: window.innerWidth > 580 ? "50px" : "30px",
  borderRadius: "50%",
  border: "1px solid #ccc",
  textAlign: "center",
  lineHeight: window.innerWidth > 580 ? "50px" : "30px",
  marginLeft: "3px",
};

const ProgressSteps = ({ steps }) => {
  const { setCurrentStep, currentStep } = useContext(AppContext);
  return (
    <divstyle style={stepsContainerStyle}>
      {steps.map((step,i) => {
        if(step.id!=='submit'){
          return (
          <div key={i}
            className={
              step.index <= currentStep ? "bg-blue-cyrcle" : "bg-white-cyrcle"
            }
            style={stepCyrclStyle}
          >
            {step.index}
          </div>
        );
        }
     
      })}
    </divstyle>
  );
};

export default ProgressSteps;
