import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import BottomButtons from "../BottomButtons";
import FormControlLabel1_5 from "../../utils/FormControlLabel1_5";

export const PhysicalBody = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const {
    selfBelief,
    goodSleep,
    water,
    fitness,
    nutrition,
    pain,
    connection,
    feelings,
    strongHealth,
  } = formData;
  const matches = useMediaQuery("(min-width:700px)");

  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;
  return (
    <Container maxWidth="xs">
      <h3>הגוף הפיזי</h3>
      <p>
        יש לסמן את המספר המתאים ביותר בסולם של 1-5 <br></br> (כאשר 1 מייצג "בכלל
        לא" ו-5 מייצג "בהחלט כן")
      </p>
      <RadioGroup
        row={matches ? "row" : ""}
        name="selfBelief"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מאמינ/ה שאת/ה יכול/ה לרפא בעצמך את גופך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="goodSleep"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מקפיד/ה על שינה טובה?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="water"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מקפיד/ה על שתיית מים קבועה מדי יום?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="fitness"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          כמה את/ה מקפיד/ה לצאת לאימון כושר קבוע או שגרת כושר קבועה?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="nutrition"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          כמה את/ה מקפיד/ה על תזונה בריאה?{" "}
        </FormLabel>
        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="pain"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה חווה כאב פיזי, קבוע או חולף, שמשפיע על התפקוד שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="connection"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה מחובר/ת לגוף שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="pain"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה חווה כאב פיזי, קבוע או חולף, שמשפיע על התפקוד שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="feelings"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מקבל/ת סימנים/תחושות מהגוף שלך שמספרים לך על מצבך הפיזיולוגי?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <RadioGroup
        row={matches ? "row" : ""}
        name="strongHealth"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה רואה את עצמך כחזק/ה בריאותית?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>
      <BottomButtons
        blueBtnStyle={blueBtnStyle}
        pinkBtnStyle={pinkBtnStyle}
        navigation={navigation}
      />
    </Container>
  );
};
