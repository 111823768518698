import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import BottomButtons from "../BottomButtons";
import FormControlLabel1_5 from "../../utils/FormControlLabel1_5";

export const MentalBody = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const {
    organized,
    financial,
    wellPlanned,
    decisions,
    negativeThoughts,
    overThinking,
    thoughtsDrivingYou,
    expressYourThoughts,
    controlYourEmotions,
  } = formData;
  const matches = useMediaQuery("(min-width:600px)");

  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;

  return (
    <Container maxWidth="xs">
      <h3>הגוף השכלי</h3>
      <p>
        יש לסמן את המספר המתאים ביותר בסולם של 1-5 <br></br> (כאשר 1 מייצג "בכלל
        לא" ו-5 מייצג "בהחלט כן")
      </p>
      <RadioGroup
        row={matches ? "row" : ""}
        name="organized"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה אדם מסודר ומאורגן בכללי?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="financial"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה אדם שמתנהל נכון ברמה הפיננסית/כלכלית?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="wellPlanned"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה אדם מתוכנן עם תוכניות ברורות קדימה?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="decisions"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          בכללי, האם קשה לך לקבל החלטות?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="negativeThoughts"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מוצא/ת את עצמך חושב/ת מחשבות שליליות על עצמך או מדמיינ/ת את
          הדבר הגרוע ביותר שיכול לקרות?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="overThinking"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה חושב/ת על סיטואציה שוב ושוב ושוב?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="thoughtsDrivingYou"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שהמחשבות שלך מנהלות אותך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="expressYourThoughts"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שאת/ה יכול/ה לתקשר ולבטא את המחשבות שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="controlYourEmotions"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מנסה לשלוט ברגשותיך באמצעות מחשבותיך בשביל לתפקד?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <BottomButtons
        blueBtnStyle={blueBtnStyle}
        pinkBtnStyle={pinkBtnStyle}
        navigation={navigation}
      />
    </Container>
  );
};
