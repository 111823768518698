import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetail from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

export const Review = ({
  formData,
  navigation,
  formDataEnglishToHebrew,
  submitForm,
  step,
  stepsLength,
}) => {
  const { go, prev } = navigation;
  const {
    name,
    gender,
    age,
    phone,
    email,

    //2
    selfBelief,
    goodSleep,
    water,
    fitness,
    nutrition,
    pain,
    connection,
    feelings,
    strongHealth,

    //3
    lastWeeks,
    dailyFunctioning,
    hardToDescribe,
    overwhelmed,
    creativeWays,
    emotionalBreakdown,
    emotionalIntensity,
    medication,

    //4
    organized,
    financial,
    wellPlanned,
    decisions,
    negativeThoughts,
    overThinking,
    thoughtsDrivingYou,
    expressYourThoughts,
    controlYourEmotions,

    //5
    creation,
    religiousIdentity,
    divineConsequences,
    termsLikeSpirit,
    innerCalling,
    energy,
    intuitions,
    connectionSoul,

    //6
    expressThePowers,
    uniqueness,
    sexuality,
    passion,
    depthCreation,
    harmony,
    doingWhatYouLove,
    socialGatherings,
  } = formData;
  const {
    Name,
    Gender,
    Age,
    Phone,
    Email,

    //2
    SelfBelief,
    GoodSleep,
    Water,
    Fitness,
    Nutrition,
    Pain,
    Connection,
    Feelings,
    StrongHealth,

    //3
    LastWeeks,
    DailyFunctioning,
    HardToDescribe,
    Overwhelmed,
    CreativeWays,
    EmotionalBreakdown,
    EmotionalIntensity,
    Medication,

    //4
    Organized,
    Financial,
    WellPlanned,
    Decisions,
    NegativeThoughts,
    OverThinking,
    ThoughtsDrivingYou,
    ExpressYourThoughts,
    ControlYourEmotions,

    //5
    Creation,
    ReligiousIdentity,
    DivineConsequences,
    TermsLikeSpirit,
    InnerCalling,
    Energy,
    Intuitions,
    ConnectionSoul,

    //6
    ExpressThePowers,
    Uniqueness,
    Sexuality,
    Passion,
    DepthCreation,
    Harmony,
    DoingWhatYouLove,
    SocialGatherings,
  } = formDataEnglishToHebrew;

  return (
    <Container style={{ minHeight: "700px" }} maxWidth="sm">
      <h3>סיכום</h3>

      <RenderAccordion
        summary="פרטים אישיים"
        step={0}
        go={go}
        details={[
          { [Name]: name },
          { [Gender]: gender },
          { [Age]: age },
          { [Phone]: phone },
          { [Email]: email },
        ]}
      />
      <RenderAccordion
        summary="הגוף הפיזי"
        go={go}
        step={1}
        details={[
          { [SelfBelief]: selfBelief },
          { [GoodSleep]: goodSleep },
          { [Water]: water },
          { [Fitness]: fitness },
          { [Nutrition]: nutrition },
          { [Pain]: pain },
          { [Connection]: connection },
          { [Feelings]: feelings },
          { [StrongHealth]: strongHealth },
        ]}
      />
      <RenderAccordion
        summary="הגוף הרגשי"
        go={go}
        step={2}
        details={[
          { [LastWeeks]: lastWeeks },
          { [DailyFunctioning]: dailyFunctioning },
          { [HardToDescribe]: hardToDescribe },
          { [Overwhelmed]: overwhelmed },
          { [CreativeWays]: creativeWays },
          { [EmotionalBreakdown]: emotionalBreakdown },
          { [EmotionalIntensity]: emotionalIntensity },
          { [Medication]: medication },
        ]}
      />
      <RenderAccordion
        summary="הגוף השכלי"
        go={go}
        step={3}
        details={[
          { [Organized]: organized },
          { [Financial]: financial },
          { [WellPlanned]: wellPlanned },
          { [Decisions]: decisions },
          { [NegativeThoughts]: negativeThoughts },
          { [ThoughtsDrivingYou]: thoughtsDrivingYou },
          { [ExpressYourThoughts]: expressYourThoughts },
          { [ControlYourEmotions]: controlYourEmotions },
        ]}
      />

      <RenderAccordion
        summary="הגוף הרוחני/נשמתי"
        go={go}
        step={4}
        details={[
          { [Creation]: creation },
          { [ReligiousIdentity]: religiousIdentity },
          { [DivineConsequences]: divineConsequences },
          { [TermsLikeSpirit]: termsLikeSpirit },
          { [InnerCalling]: innerCalling },
          { [Energy]: energy },
          { [Intuitions]: intuitions },
          { [ConnectionSoul]: connectionSoul },
        ]}
      />

      <RenderAccordion
        summary="הגוף השלם - מיני ויצרי"
        go={go}
        step={5}
        details={[
          { [ExpressThePowers]: expressThePowers },
          { [Uniqueness]: uniqueness },
          { [Sexuality]: sexuality },
          { [Passion]: passion },
          { [DepthCreation]: depthCreation },
          { [Harmony]: harmony },
          { [DoingWhatYouLove]: doingWhatYouLove },
          { [SocialGatherings]: socialGatherings },
        ]}
      />
      <Button
        color="primary"
        variant="contained"
        style={{ marginTop: "1.5rem", marginBottom: "3rem" }}
        onClick={submitForm}
      >
        שליחה
      </Button>
    </Container>
  );
};

export const RenderAccordion = ({ summary, details, go, step }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetail>
      <div>
        {details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          {
            /* const objValue = data[Object.keys(data)[0]]; */
          }
          const objValue = data[objKey];

          return (
            <ListItemText key={index}>{`${objKey}: ${objValue}`}</ListItemText>
          );
        })}
        <IconButton color="primary" component="span" onClick={() => go(step)}>
          <EditIcon />
        </IconButton>
      </div>
    </AccordionDetail>
  </Accordion>
);
