import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../App";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import { useContext } from "react";

export const PersonalDetails = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const { name, gender, age, phone, email } = formData;
  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;
  const { setCurrentStep, currentStep } = useContext(AppContext);
  const matches = useMediaQuery("(min-width:700px)");
  const forward = () => {
    navigation.next();
    window.scrollTo(1, 0);

    setCurrentStep((prev) => prev * 1 + 1);
  };
  return (
    <Container maxWidth="xs">
      <h3>פרטים אישיים </h3>

      <TextField
        label="שם"
        name="name"
        value={name}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />

      <RadioGroup
        defaultValue="female"
        name="gender"
        onChange={setForm}
        margin="normal"
        // variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>מגדר</FormLabel>

        <FormControlLabel
          value="female"
          control={<Radio style={radioStyle} size="small" />}
          label="נקבה"
        />
        <FormControlLabel
          value="male"
          control={<Radio style={radioStyle} size="small" />}
          label="זכר"
        />
        <FormControlLabel
          value="other"
          control={<Radio style={radioStyle} size="small" />}
          label="אחר"
        />
      </RadioGroup>

      <RadioGroup
        defaultValue="18-25"
        name="age"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>גיל</FormLabel>

        <FormControlLabel
          value="18-25"
          control={<Radio style={radioStyle} size="small" />}
          label="18-25"
        />
        <FormControlLabel
          value="26-33"
          control={<Radio style={radioStyle} size="small" />}
          label="26-33"
        />
        <FormControlLabel
          value="34-44"
          control={<Radio style={radioStyle} size="small" />}
          label="34-44"
        />
        <FormControlLabel
          value="45-55"
          control={<Radio style={radioStyle} size="small" />}
          label="45-55"
        />
        <FormControlLabel
          value="55+"
          control={<Radio style={radioStyle} size="small" />}
          label="55+"
        />
      </RadioGroup>

      <TextField
        label="טלפון"
        name="phone"
        value={phone}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
      <TextField
        label="אימייל"
        name="email"
        value={email}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
      <Button variant="contained" style={blueBtnStyle} onClick={forward}>
        המשך
      </Button>
    </Container>
  );
};
