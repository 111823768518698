import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
import BottomButtons from "../BottomButtons";
import FormControlLabel1_5 from "../../utils/FormControlLabel1_5";

export const WholeBody = ({
  formData,
  setForm,
  navigation,
  blueBtnStyle,
  pinkBtnStyle,
  radioGroupStyle,
  step,
  stepsLength,
}) => {
  const {
    expressThePowers,
    uniqueness,
    sexuality,
    passion,
    depthCreation,
    harmony,
    doingWhatYouLove,
    socialGatherings,
  } = formData;
  const matches = useMediaQuery("(min-width:700px)");

  const { wraperStyle, mainLabelStyle, subMainLabelStyle, radioStyle } =
    radioGroupStyle;
  return (
    <Container maxWidth="xs">
      <h3>הגוף השלם - יצרי ומיני</h3>
      <p>
        יש לסמן את המספר המתאים ביותר בסולם של 1-5 <br></br> (כאשר 1 מייצג "בכלל
        לא" ו-5 מייצג "בהחלט כן")
      </p>
      <RadioGroup
        row={matches ? "row" : ""}
        name="expressThePowers"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מצליח/ה לבטא החוצה את הכוחות הקיימים בך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="uniqueness"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מאמינ/ה בייחודיות שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="sexuality"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          האם את/ה מרגיש/ה מחובר/ת ובנוח עם המיניות שלך?
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="passion"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>עד כמה תשוקה נוכחת בחייך? </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="depthCreation"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שאת/ה מביא/ה לידי ביטוי את עומק היצירה שלך?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="harmony"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שיש בך הרמוניה מלאה בין כלל הגופים שבתוכך
          (פיזי-רגשי-שכלי-רוחני-יצרי)?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="doingWhatYouLove"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה את/ה מרגיש/ה שאת/ה עושה את מה שאת/ה אוהב/ת?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <RadioGroup
        row={matches ? "row" : ""}
        name="socialGatherings"
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
        style={wraperStyle}
      >
        <FormLabel style={mainLabelStyle}>
          עד כמה יש לך מפגשים חברתיים תכופים עם משפחה ו/או חברים?{" "}
        </FormLabel>

        <FormControlLabel1_5
          radioStyle={radioStyle}
          subMainLabelStyle={subMainLabelStyle}
        />
      </RadioGroup>

      <BottomButtons
        blueBtnStyle={blueBtnStyle}
        pinkBtnStyle={pinkBtnStyle}
        navigation={navigation}
      />
    </Container>
  );
};
