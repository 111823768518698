export const buttonsStyle = {
  blue: {
    borderRadius: 5,
    backgroundColor: "#201f5b",
    padding: "8px 20px",
    fontSize: "18px",
    marginLeft: "8px",
    color: "white",
    width: "50%",
  },
  pink: {
    borderRadius: 5,
    backgroundColor: "#e61e82",
    padding: "8px 20px",
    fontSize: "18px",
    marginLeft: "8px",
    color: "white",
    width: "50%",
  },
};
