import {
  FormControlLabel,
  FormLabel,
  Radio,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment } from "react";

const FormControlLabel1_5 = (props) => {
  const radioStyle = props.radioStyle;
  const subMainLabelStyle = props.subMainLabelStyle;
  const matches = useMediaQuery("(min-width:700px)");

  return (
    <Fragment>
      <div className="flex-r-c-b">
        <FormLabel style={subMainLabelStyle}>במידה מעטה</FormLabel>
      </div>
      <FormControlLabel
        value="1"
        control={<Radio style={radioStyle} size="small" />}
        label="1"
        labelPlacement={matches ? "top" : "end"}
      />
      <FormControlLabel
        value="2"
        control={<Radio style={radioStyle} size="small" />}
        label="2"
        labelPlacement={matches ? "top" : "end"}
      />
      <FormControlLabel
        value="3"
        control={<Radio style={radioStyle} size="small" />}
        label="3"
        labelPlacement={matches ? "top" : "end"}
      />
      <FormControlLabel
        value="4"
        control={<Radio style={radioStyle} size="small" />}
        label="4"
        labelPlacement={matches ? "top" : "end"}
      />
      <FormControlLabel
        value="5"
        control={<Radio style={radioStyle} size="small" />}
        label="5"
        labelPlacement={matches ? "top" : "end"}
      />

      <div className="flex-r-c-b">
        <FormLabel style={subMainLabelStyle}>במידה רבה</FormLabel>
      </div>
    </Fragment>
  );
};

export default FormControlLabel1_5;
