import React from "react";

const Header = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        backgroundColor: "#fffffc",
        color: "#201f5b",
        fontSize: window.innerWidth  > 580 ? 35 : 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      }}
    >
      <div style={{ width: "10%", padding: "20px" }}>
        <img style={{ height: "80px" }} src="./dan-logo.png" alt="" />
      </div>
      <div style={{ padding: 20, textAlign: "center", width: "80%" }}>
        שאלון חמשת הגופים <br />
        <span
          style={{ fontSize: window.innerWidth  > 580 ? 25 : 14, fontWeight: 100 }}
        >
          לעלות בתדר ההגשמתי
        </span>
      </div>
      <div style={{ width: "10%", height: "100px", padding: "20px" }}></div>
    </div>
  );
};

export default Header;
