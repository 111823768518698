import { Box } from "@material-ui/core";
import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80px",
        backgroundColor: "#201f5b",
        color: "#fff",
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        marginTop:30,
    
      }}
    >
      <div style={{ width: 200 ,marginBottom:10}}>Mili-weB תוכנות | אתרים | אפליקציות</div>
    </div>
  );
};

export default Footer;
